<template>
  <div>
    <b-overlay :show="loading">

      <validation-observer ref="refFormObserver">
        <validation-provider #default="validationContext" name="Name" rules="required">
          <b-form-group :label="$t('message.Name')" for="name">
            <b-form-input autofocus v-model="users.name"></b-form-input>
            <span class="text-danger">
              {{ validationContext.errors[0] }}
            </span>
          </b-form-group>
        </validation-provider>
        <validation-provider #default="validationContext" name="Full name" rules="required">
          <b-form-group :label="$t('message.Full_name')" for="full_name">
            <b-form-input autofocus v-model="users.full_name"></b-form-input>
            <span class="text-danger">
              {{ validationContext.errors[0] }}
            </span>
          </b-form-group>
        </validation-provider>

        <validation-provider #default="validationContext" name="Email" rules="required">
          <b-form-group :label="$t('message.Email')" for="email">
            <b-form-input autofocus v-model="users.email"></b-form-input>
            <span class="text-danger">
              {{ validationContext.errors[0] }}
            </span>
          </b-form-group>
        </validation-provider>

        <ValidationProvider #default="validationContext" :name="$t('message.Branches')" rules="required">
          <div class="wrapper">
            <p class="wrapper-title">
              {{$t('message.Branches')}}
            </p>
            <div class="wrapper-form">
              <v-select v-model="users.branch_id" :options="branchs" label="name" :reduce="(options) => options.id">
              </v-select>
            </div>
            <span class="text-danger">
              {{ validationContext.errors[0] }}
            </span>
          </div>
        </ValidationProvider>


        <!-- <label class="mt-2">{{$t('message.Branches')}}</label>
      <b-form-select v-model="users.branch_id" :options="branchs" text-field="name" value-field="id"
        style="height: 40px" class="w-100" />

      <span class="text-danger" v-if="users.branch_id === null">
        {{$t('message.errorBranch')}}
      </span> -->

        <ValidationProvider #default="validationContext" :name="$t('message.Roles')" rules="required">
          <div class="wrapper">
            <p class="wrapper-title">
              {{$t('message.Roles')}}
            </p>
            <div class="wrapper-form">
              <v-select v-model="userRoles"  :options="rolesOptions" label="name" :reduce="(options) => options.id"></v-select>
            </div>
            <span class="text-danger">
              {{ validationContext.errors[0] }}
            </span>
          </div>
        </ValidationProvider>
        <!-- <label class="mt-2">{{$t('message.Roles')}}</label>
      <v-select :options="roles" v-model="userRoles" label="name" multiple></v-select>
      <span class="text-danger" v-if="userRoles === null">
        {{$t('message.roleErrr')}}
      </span> -->

        <!--      <div class="w-100 mb-2">-->
        <!--        <h5>Contacts</h5>-->
        <!--        <b-row v-for="(num, numIndex) in form.contacts" :key="numIndex" class="mt-2">-->
        <!--          <b-col cols="10">-->
        <!--            <validation-provider-->
        <!--                #default="validationContext"-->
        <!--                name="Full Name"-->
        <!--                rules="required"-->
        <!--            >-->
        <!--              <b-form-input autofocus v-model="num.number" class="w-100"></b-form-input>-->
        <!--              <b-form-invalid-feedback>-->
        <!--                {{ validationContext.errors[0] }}-->
        <!--              </b-form-invalid-feedback>-->
        <!--            </validation-provider>-->
        <!--          </b-col>-->
        <!--          <b-col cols="2">-->
        <!--            <b-button variant="outline-danger" size="sm" @click="removeNumber(numIndex)">-->
        <!--              <b-icon icon="trash"></b-icon>-->
        <!--            </b-button>-->
        <!--          </b-col>-->
        <!--        </b-row>-->
        <!--        <hr>-->
        <!--        <div class="w-100 d-flex justify-content-end mt-2">-->
        <!--          <b-button variant="outline-success" size="sm" @click="increaseContact">-->
        <!--            <b-icon icon="plus"></b-icon>-->
        <!--          </b-button>-->
        <!--        </div>-->
        <!--      </div>-->

        <div class="w-100 mt-1">
          <validation-provider #default="validationContext" name="Password" rules="required">
            <label for="password">{{$t('message.Password')}}</label>
            <b-form-input v-model="users.password" type="password" name="password"></b-form-input>
            <span class="text-danger">
              {{ validationContext.errors[0] }}
            </span>
          </validation-provider>
        </div>

        <div class="w-100 d-flex justify-content-end mt-3">
          <b-button variant="primary" @click="handleSubmit" :disabled="disabled">
            {{$t('message.Save')}}
          </b-button>
          <b-button variant="warning" class="ml-1" @click="$emit('closeModal')">
            {{$t('message.Cancel')}}
          </b-button>
        </div>
      </validation-observer>
    </b-overlay>

  </div>
</template>

<script>
  import {
    ValidationProvider,
    ValidationObserver
  } from 'vee-validate'
  import {
    required,
    alphaNum
  } from '@validations'
  import {
    addUsers,
    editUsers,
    getUsersById
  } from '@/api/users/users'
  import {
    getRoles,
    getRolesById
  } from '@/api/roles/roles'
  import vSelect from 'vue-select'
  import {
    getBranches
  } from '@/api/branchs/branchs'

  export default {
    name: 'UserForm',
    components: {
      ValidationProvider,
      ValidationObserver,
      vSelect,
    },
    props: {
      branches: '',
      id: {
        default: null
      }
    },
    data() {
      return {
        required,
        alphaNum,
        users: {
          name: '',
          full_name: '',
          email: '',
          branch_id: null,
          roles: [],
          password: '',
        },
        userRoles: null,
        branchs: [],
        rolesOptions: [],
        disabled: false,
        loading: false,
      }
    },
    mounted() {
      this.fetchUsers()
    },
    methods: {
      fetchUsers() {
        getRoles().then(response => {
          this.rolesOptions = response.data.data
        });
        getBranches().then(res => {
          this.branchs = res.data.data;
        })
      },
      getUserById() {
        getUsersById({
          id: this.id
        }).then(response => {
          this.users = response.data.data
          this.userRoles = this.users.roles;
        })
      },
      handleSubmit() {
        const isValid = this.$refs.refFormObserver.validate();
        if (isValid) {
          this.disabled = true;
          this.loading = true;
          setTimeout(() => {
            this.disabled = false;
            this.loading = false;
          }, 1000)
          this.users.roles.push(this.userRoles)
          let data = {...this.users};

          if (this.id) {
            console.log('isLOGED');
            // eslint-disable-next-line no-unused-vars
            editUsers(data).then(res => {
              this.$emit('closeModal')
            })
          } else {
            // eslint-disable-next-line no-unused-vars
            addUsers(data).then(res => {
              this.$emit('closeModal')
            })
          }
        }
      },
    },
    watch: {
      id: {
        immediate: true,
        handler(val) {
          if (val) {
            this.getUserById()
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>