<template>
  <div>
    <b-overlay :show="loading">
      <div class="w-100 d-flex justify-content-end">
        <b-button variant="primary" class="mb-1" @click="handleAdd">
          <b-icon icon="plus"></b-icon>{{$t('message.Add')}}
        </b-button>
      </div>
      <b-card>
        <b-table :fields="fields" :items="users" responsive :busy="busy">
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(checkbox)="{item}">
            <b-form-checkbox v-model="item.is_active" value="true" unchecked-value="false"
              @change="handleUpdateUser(item)"></b-form-checkbox>
          </template>

          <template #cell(roles)="{item}">
            <span v-for="(role, index) in item.roles" :key="index">
              {{role.name}}
              <span v-if="index !== (item.roles.length - 1)"> / </span>
            </span>
          </template>

          <template #cell(action)="{item}">
            <div class="d-flex">
              <div>
                <b-button variant="outline-warning" size="sm" @click="handleEdit(item.id)">
                  <b-icon icon="pencil"></b-icon>
                </b-button>
              </div>
              <div class="ml-1">
              <b-button variant="outline-danger" size="sm" @click="handleDelete(item.id)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
            </div>
          </template>
        </b-table>
      </b-card>

      <b-pagination v-model="pagination.page" :total-rows="total" :per-page="pagination.pageSize" @input="fetchUsers">
      </b-pagination>
    </b-overlay>

    <b-modal hide-footer v-model="formModalActive" no-close-on-backdrop>
      <UserForm :id="id" @closeModal="closeModal" />
    </b-modal>
  </div>
</template>

<script>
  import Swal from 'sweetalert2'
  import UserForm from '@/views/directories/users/components/UserForm'
  import {
    createUsersById,
    getUsers,
    deleteUsers,
    editUsers
  } from '@/api/users/users'
  import {
    createRolesById
  } from '@/api/roles/roles'

  export default {
    name: 'Index',
    components: {
      UserForm
    },
    data() {
      return {
        branches: [],
        roles: [],
        users: [],
        id: null,
        fields: [{
            key: 'checkbox',
            label: '#'
          },
          {
            key: 'id',
            label: 'ID'
          },
          {
            key: 'avatar',
            label: 'Avatar'
          },
          {
            key: 'name',
            label: this.$t('message.name_title')
          },
          {
            key: 'full_name',
            label: this.$t('message.Full_name')
          },
          {
            key: "email",
            label: this.$t('message.Email')
          },
          // {
          //   key: 'contacts',
          //   label: this.$t('Contact_Number')
          // },
          {
            key: "roles",
            label: this.$t('message.Role')
          },
          {
            key: 'branch.name',
            label: this.$t('message.Branches')
          },
          {
            key: 'action',
            label: this.$t('message.Action')
          },
        ],
        params: {
          brand_id: null,
          roles_id: null
        },
        formModalActive: false,
        pagination: {
          page: 1,
          pageSize: 20
        },
        total: 1,
        busy: true,
        loading: false,
      }
    },
    created() {
      this.fetchUsers()
    },
    methods: {
      fetchUsers() {
        this.loading = true;
        getUsers(this.pagination).then(response => {
          this.users = response.data.data;
          this.total = response.data.total;
          this.busy = false;
          this.loading = false;
        })
      },
      handleAdd() {
        this.id = null;
        this.formModalActive = true;
      },
      handleEdit(id) {
        this.id = id;
        this.formModalActive = true;
      },
      closeModal() {
        this.formModalActive = false;
        this.fetchUsers();
      },
      handleDelete(id) {
        if (id) {
          Swal.fire({
            title: this.$t('areYouSure'),
            text: this.$t('wontReturn'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.$t('YesDelete'),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              // eslint-disable-next-line no-unused-vars
              deleteUsers({
                id
              }).then(res => {
                Swal.fire({
                  icon: 'success',
                  position: 'center',
                  title: this.$t('Deleted'),
                  text: this.$t('dataHasDeleted'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.fetchUsers()
              })
            } else if (result.dismiss === 'cancel') {
              Swal.fire({
                title: this.$t('Cancelled'),
                text: this.$t('yrDataSafe'),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
        }
      },
      handleUpdateUser(item) {
        let data = {
          ...item
        }
        if (item.is_active === 'true') {
          data.is_active = true;
        } else if (item.is_active === 'false') {
          data.is_active = false;
        }
        editUsers(data).then(() => {
          this.fetchUsers()
        })
      }
    }
  }
</script>